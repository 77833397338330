import axios from "axios";
import { url_api } from "../constants/url";

const token = localStorage.getItem("token");

export const client = axios.create({ baseURL: url_api });

client.interceptors.request.use(
  (config) => {
    // Check if the token is available and not expired
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
